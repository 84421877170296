import React from 'react';
import {apiHost} from '../../../common/config';
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Select from "../../../components/UI/Select/Select";
import Textarea from "../../../components/UI/Textarea/Textarea";
import RadioInput from "../../../components/UI/Radio/Radio";
import CustomDatePicker from "../../../components/UI/DatePicker/DatePicker";
import axios from "axios";
import store from "../../../store/store";
import Modal from '../../../components/modal/modal';
import CustomTable from '../../../components/table/table';
import ConsultantCustomTable from '../../../components/table-consultant/table';
import Layout from "../../../layouts/authorized";
import ProcessingModal from "../../../components/modal/processingModal";

class SalesUploadForm extends React.Component {


    constructor(props) {
        super(props);

        this.roleText = this.props.role === 'doctor' ? 'врача' : 'консультанта';

        this.state = {
            showModalOpen: false,
            showModalText: '',
            status: 'ready',
            designList: [],
            startDate: new Date(),
            coveringList: [],
            nameList: [],
            sourceList: [],
            groupList: [],
            technologyList: [],
            uploadedStatus: false,
            headers: [
                {code: 'date', caption: 'Дата', type: 'date'},
                {code: 'source', caption: 'Источник', type: 'text'},
                {code: 'group', caption: 'Группа', type: 'text'},
                {code: 'name', caption: 'Наименование', type: 'text'},
                {code: 'design', caption: 'Дизайн', type: 'text'},
                {code: 'covering', caption: 'Покрытие', type: 'text'},
                {code: 'count', caption: 'Кол-во линз', type: 'number'},
                {code: 'technology', caption: 'Технология', type: 'text'},
                {code: 'points', caption: 'Баллы', type: 'number'},
                {code: 'status', caption: 'Статус', type: 'text'},
                {code: 'comment', caption: 'Примечание ' + this.roleText, type: 'text'},
            ],
            tableData: [],
            showRetroModalOpen: false,
            showRetroModalText: '',
            retro: false,
        }

    }

    componentDidMount() {
        axios.get(
            `${apiHost}/sale/get/formData`,
            {withCredentials: true}
        ).then(res => {
            this.setState({
                coveringList: res.data.covering,
                designList: res.data.design,
                nameList: res.data.names,
                sourceList: res.data.source,
                groupList: res.data.group,
                technologyList: [],
                groupFetched: false,
                designFetched: false,
                namesFetched: false,
                coveringFetched: false,
                technologyFetched: false,
                sourceSelected: null,
                groupSelected: null,
                designSelected: null,
                nameSelected: null,
                coveringSelected: null,
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        this.getTableData();
        this.getEntity();
    }

    submitHandler (event) {
        event.preventDefault();
        this.setState({status: 'processing'});
        const formData = new FormData(event.target);

        axios(`${apiHost}/sale/add`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            this.setState({status: 'ready'});
            if (res.data.status === 'success') {
                this.setState({
                    showModalOpen: true,
                    showModalText: 'Данные успешно загружены.',
                    uploadedStatus: true
                });
            } else {
                this.setState({
                    showModalOpen: true,
                    showModalText: res.data.error
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })

            this.setState({status: 'ready'});
        });
    }

    getEntity() {
        axios.get(
            `${apiHost}/entity/getUserEntity`,
            {withCredentials: true}
        ).then(res => {
            if(res.data.RETRO == '1')
            {
                this.setState({
                    retro: true
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    closeModal() {
        const { uploadedStatus } = this.state;

        if (uploadedStatus) {
            this.getTableData();
        }

        this.setState({showModalOpen: false, uploadedStatus: false});
    }

    fetchGroups(source) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source
                }
            }
        ).then(res => {
            this.setState({
                groupList: res.data.UF_GROUP,
                groupFetched: true,
                designFetched: false,
                namesFetched: false,
                coveringFetched: false,
                sourceSelected: source,
                groupSelected: null,
                designSelected: null,
                nameSelected: null,
                coveringSelected: null,
                coveringList: [],
                designList: [],
                nameList: [],
                technologyList: [],
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchDesign(source, group) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source,
                    group: group,
                }
            }
        ).then(res => {
            this.setState({
                designList: res.data.UF_DESIGN,
                groupFetched: true,
                designFetched: true,
                namesFetched: false,
                coveringFetched: false,
                sourceSelected: source,
                groupSelected: group,
                designSelected: null,
                nameSelected: null,
                coveringSelected: null,
                coveringList: [],
                nameList: [],
                technologyList: [],
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchNames(source, group, design) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source,
                    group: group,
                    design: design,
                }
            }
        ).then(res => {
            this.setState({
                nameList: res.data.UF_NAME,
                groupFetched: true,
                designFetched: true,
                namesFetched: true,
                coveringFetched: false,
                sourceSelected: source,
                groupSelected: group,
                designSelected: design,
                nameSelected: null,
                coveringSelected: null,
                coveringList: [],
                technologyList: [],
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchCovering(source, group, design, name) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source,
                    group: group,
                    design: design,
                    name: name,
                }
            }
        ).then(res => {
            this.setState({
                coveringList: res.data.UF_COVERING,
                groupFetched: true,
                designFetched: true,
                namesFetched: true,
                coveringFetched: true,
                sourceSelected: source,
                groupSelected: group,
                designSelected: design,
                nameSelected: name,
                coveringSelected: null,
                technologyList: [],
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchTechnology(source, group, design, name, covering) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source,
                    group: group,
                    design: design,
                    name: name,
                    covering: covering,
                }
            }
        ).then(res => {
            this.setState({
                technologyList: res.data.UF_TECHNOLOGY,
                groupFetched: true,
                designFetched: true,
                namesFetched: true,
                coveringFetched: true,
                sourceSelected: source,
                groupSelected: group,
                designSelected: design,
                nameSelected: name,
                coveringSelected: covering,
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getTableData() {
        axios.get(
            `${apiHost}/sale/get/userSales`,
            {withCredentials: true}
        ).then(res => {
            this.setState({
                tableData: res.data
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    closeRetroModal() {
        this.setState({showRetroModalOpen: false});
    }

    showRetroModal() {
        this.setState({showRetroModalOpen: !this.state.showRetroModalOpen});
    }

    render() {
        return (
            <>
                <ProcessingModal showModalOpen={this.state.status !== 'ready'} />

                <a hidden={!this.state.retro} onClick={() => this.showRetroModal()} style={{
                    color: '#fff',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img hidden={!this.state.retro} style={{ marginRight: '15px' }} src="/assets/resources/question.svg" alt=""/>
                    <span hidden={!this.state.retro} >Информация о разделе</span>
                </a><br></br>
                <div
                    style={{
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        minHeight: '150px',
                        marginBottom: '25px',
                        marginTop: '25px',
                        width: '75%',
                        borderRadius: '25px',
                        padding: '15px'
                    }}
                    hidden={!this.state.showRetroModalOpen}>
                    В данный раздел Вам необходимо внести Ваши продажи. Для этого Вам нужно внести информацию по дизайну линзы, покрытию, технологии, указать кол-во проданных линз и нажать <strong>“Добавить”</strong>. После этого в таблице ниже появится Ваша продажа. В столбце <strong>“Статус”</strong> Вы можете отслеживать зачислены ли баллы за внесённую Вами продажу.<br></br>
                    <br></br>
                    Каждая внесённая продажа может иметь один из трёх статусов:<br></br>
                    • В обработке - руководитель не подтвердил продажу, баллы не зачислены;<br></br>
                    • Принята - руководитель подтвердил продажу, баллы за продажу зачислены;<br></br>
                    • Отклонена - руководитель отклонил Вашу продажу, баллы зачислены не будут.<br></br>
                    Вы можете выгрузить все внесённые Вами продажи в таблицу excel нажав на кнопку <strong>“Выгрузить в excel”</strong> расположенную под таблицей в правом нижнем углу.<br></br>
                    <br></br>
                    Мы рекомендуем Вам вносить свои продажи в течении 1-2 дней с момента осуществления продажи.<br></br>
                    <br></br>
                    После подтверждения всех продаж Руководителем с 1 по 9 число следующего месяца предыдущий квартал будет закрыт, и Вы не сможете внести новые продажи за предыдущий квартал, соответственно, Вы не сможете получить за них баллы. В связи с этим просим вносить продажи своевременно.<br></br>

                </div>

                <div className="w-full md:max-w-3/4">
                    <h4 className="mb-5">{this.props.role === 'doctor' ? 'Добавить рекомендации' : 'Добавить продажи'}</h4>
                    <form onSubmit={(event) => this.submitHandler(event)}
                          method="post">
                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-3/12 md:mr-5">
                                <CustomDatePicker/>
                            </div>

                            <div className="md:w-9/12 md:mr-5">

                                <Select
                                    name="source"
                                    label="Источник"
                                    placeholder="Выберите из списка"
                                    options={this.state.sourceList}
                                    className="w-full"
                                    onChange={e => {
                                        this.fetchGroups(
                                            e.value,
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        <div className="md:w-9/12 md:mr-5">
                            <Select
                                name="group"
                                label="Группа"
                                placeholder="Выберите из списка"
                                options={this.state.groupList}
                                className="w-full"
                                isDisabled={!this.state.groupFetched}
                                onChange={e => {
                                    this.fetchDesign(
                                        this.state.sourceSelected,
                                        e.value,
                                    );
                                }}
                            />
                        </div>

                        <div className="md:w-9/12">
                            <Select
                                name="design"
                                label="Дизайн"
                                placeholder="Выберите наименование линзы"
                                options={this.state.designList}
                                className="w-full"
                                isDisabled={!this.state.designFetched}
                                onChange={e => {
                                    this.fetchNames(
                                        this.state.sourceSelected,
                                        this.state.groupSelected,
                                        e.value,
                                    );
                                }}
                            />
                        </div>

                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-9/12 md:mr-5">
                                <Select
                                    name="name"
                                    label="Наименование"
                                    placeholder="Выберите из списка"
                                    options={this.state.nameList}
                                    className="w-full"
                                    isDisabled={!this.state.namesFetched}
                                    onChange={e => {
                                        this.fetchCovering(
                                            this.state.sourceSelected,
                                            this.state.groupSelected,
                                            this.state.designSelected,
                                            e.value,
                                        );
                                    }}
                                />
                            </div>

                            <div className="md:w-9/12 md:mr-5">
                                <Select
                                    name="covering"
                                    label="Покрытиe"
                                    placeholder="Выберите из списка"
                                    options={this.state.coveringList}
                                    className="w-full"
                                    isDisabled={!this.state.coveringFetched}
                                    onChange={e => {
                                        this.fetchTechnology(
                                            this.state.sourceSelected,
                                            this.state.groupSelected,
                                            this.state.designSelected,
                                            this.state.nameSelected,
                                            e.value,
                                        );
                                    }}
                                />
                            </div>

                            <div className="md:w-3/12">
                                <Input label="Кол-во линз"
                                       name="count"
                                       type="number"
                                       min="1"
                                       max="100"
                                       className="w-full max-w-3xl"/>
                            </div>
                        </div>

                        <RadioInput
                            defaultChecked
                            name="technology"
                            value=""
                            label='Без дополнительных технологий'/>

                        {this.state.technologyList.map(({label, value}) => <RadioInput name="technology" value={value} label={label} />)}

                        <Textarea name="comment"
                                  label={`Примечание ${this.roleText}`}
                                  placeholder="Введите текст"
                                  className="w-full max-w-3xl"
                                  rows={4}/>

                        <Button type="submit" className="btn btn-blue btn-thin uppercase my-8"
                                disabled={this.state.status !== 'ready'}>
                            Добавить
                        </Button>
                    </form>
                    <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                           boxStyle={{maxWidth: '38rem'}}>
                        <div className="text-center">
                            <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">Загрузка продаж!</h2>
                            <p className="text-center">{this.state.showModalText}</p>

                            <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                    onClick={() => this.closeModal()}>Закрыть</Button>
                        </div>
                    </Modal>
                </div>


                <div className="horizontal-line mt-10 mb-10"></div>

                {  this.state.tableData.length > 0 &&
                    <ConsultantCustomTable
                        headers={this.state.headers}
                        tableData={this.state.tableData}
                        exportLink={`${apiHost}/export/sales`}
                    />
                }
            </>
        )
    }

}

export default SalesUploadForm;
