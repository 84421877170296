import React, { useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import "./DatePicker.scss";
import moment from "moment"

import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';


class CustomDatePicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: this.props.date ? moment(this.props.date, 'DD.MM.YYYY').toDate() : new Date()
        }
    }

    handleChange = date => {
        console.log(date);
        console.log(this.state);

        this.setState({
            startDate: date
        });
    };

    render() {
        const ref = React.createRef();

        const CustomInput = forwardRef(({ value, onClick }, _ref) => (
            <div className="datepicker">
                <label className="datepicker__label">
                    Дата
                </label>
                <div className="datepicker__custom-input" onClick={onClick}>
                    {value}
                    <i className="datepicker__icon icon-calendar"></i>
                </div>
                <input type="hidden" name="date" value={this.state.startDate} />
            </div>
        ));

        return (
            <DatePicker
                selected={this.props.date ? moment(this.props.date, 'DD.MM.YYYY').toDate() : this.state.startDate}
                onChange={this.handleChange}
                customInput={<CustomInput ref={ref} />}
                locale={ru}
            />
        )
    }
};

export default CustomDatePicker;